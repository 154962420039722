import React from "react";
import {
  FaMapMarkerAlt,
  FaLinkedin,
  FaProductHunt,
  FaGithub,
  FaEnvelope,
} from "react-icons/fa";

const Profile = () => {
  return (
    <div className="flex flex-col items-center md:items-start p-10  rounded-lg md:ml-24 z-10">
      {/* Image de profil */}
      <img
        src="logo.png"
        alt="Julien Zammit"
        className="rounded-full w-48 h-48 mb-6"
      />
      {/* Nom */}
      <h1 className="text-4xl font-bold text-gray-800 mb-4">Julien Zammit</h1>

      {/* Université et Description */}
      <div className="flex items-center mb-4 space-x-4">
        <img
          src="centrale-lille-logo.png"
          alt="Centrale Lille"
          className="w-12 h-12"
        />
        <p className="text-gray-700 text-xl">
          Engineering student in IT at Centrale Lille
        </p>
      </div>

      {/* Localisation */}
      <p className="text-gray-700 text-xl mb-4 flex items-center">
        <FaMapMarkerAlt className="inline-block mr-2 text-red-500" />
        Lille
      </p>

      {/* Email */}
      <p className="text-gray-700 text-xl flex items-center mb-4">
        <FaEnvelope className="inline-block mr-2 text-blue-500" />
        <a href="mailto:contact@nanosaasify.com" className="hover:underline">
          contact@nanosaasify.com
        </a>
      </p>

      {/* Icônes de réseaux sociaux */}
      <div className="flex space-x-6 mb-8">
        <a
          href="https://www.linkedin.com/in/julienzammit"
          className="transform transition-transform duration-300 hover:scale-110 hover:text-blue-600"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin className="text-gray-700 w-8 h-8" />
        </a>
        <a
          href="https://www.producthunt.com/@julien_zammit"
          className="transform transition-transform duration-300 hover:scale-110 hover:text-red-600"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaProductHunt className="text-gray-700 w-8 h-8" />
        </a>
        <a
          href="https://github.com/julienzammit"
          className="transform transition-transform duration-300 hover:scale-110 hover:text-black"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub className="text-gray-700 w-8 h-8" />
        </a>
      </div>

      {/* Description des projets */}
      <p className="text-gray-700 text-xl text-center md:text-left mb-3 max-w-lg">
        You can see here all my Nano SaaS projects and web development work. I'm
        a full-stack developer and I love to create new products. Hope you find
        something useful here.
      </p>

      {/* Compétences */}
      <p className="text-gray-500 italic text-center md:text-left mb-8 max-w-lg">
        Skills: artificial intelligence (advanced prompt engineering, AI model fine-tuning, natural language processing (NLP), and integration of AI into web applications), project
        management, sales, client communication, and full-stack development.
      </p>
    </div>
  );
};

export default Profile;
